.MyPageRadioButton {
}

.MyPageRadioGroup:not(.MyPageRadioGroup-lg) .MyPageRadioButton.ant-radio-button-wrapper {
  background-color: transparent;
  display: flex;

  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.4px;

  color: var(--action-primary-color);
  border: none;
  border-radius: 8px;
}

.MyPageRadioGroup:not(.MyPageRadioGroup-lg) .MyPageRadioButton.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #ffffff;
}

.MyPageRadioGroup:not(.MyPageRadioGroup-lg) .MyPageRadioButton:not(.ant-radio-button-wrapper-checked):first-child {
  border-left: none;
}

.MyPageRadioGroup:not(.MyPageRadioGroup-lg) .MyPageRadioButton.ant-radio-button-wrapper:not(:first-child)::before {
  background-color: transparent;
}

.MyPageRadioGroup:not(.MyPageRadioGroup-lg) .MyPageRadioButton.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background-color: var(--action-primary-color);
}

.MyPageRadioGroup:not(.MyPageRadioGroup-lg) .MyPageRadioButton > *:not(.ant-radio-button) {
  flex-grow: 1;
  display: flex;
  justify-content: center;
}



.MyPageRadioGroup-lg .MyPageRadioButton.ant-radio-button-wrapper {
  background-color: transparent;
  display: flex;

  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.4px;

  color: var(--action-primary-color);
  border-color: var(--action-primary-color);
  box-shadow: -1px 0 0 0 var(--action-primary-color);
}

.MyPageRadioGroup-lg .MyPageRadioButton.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #ffffff;
}

.MyPageRadioGroup-lg .MyPageRadioButton:not(.ant-radio-button-wrapper-checked):first-child {
  border-left: none;
}

.MyPageRadioGroup-lg .MyPageRadioButton.ant-radio-button-wrapper:not(:first-child)::before {
  background-color: transparent;
}

.MyPageRadioGroup-lg .MyPageRadioButton.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background-color: var(--action-primary-color);
}

.MyPageRadioGroup-lg .MyPageRadioButton > *:not(.ant-radio-button) {
  flex-grow: 1;
  display: flex;
  justify-content: center;
}

.MyPageRadioGroup-lg .MyPageRadioButton:first-child {
  border-radius: 8px 0 0 8px;
}

.MyPageRadioGroup-lg .MyPageRadioButton:last-child {
  border-radius: 0 8px 8px 0;
}
