.DesktopForgottenPassword .RecoverySteps {
  width: 400px;
  margin: 16px;
}

.DesktopForgottenPassword .RecoverySteps > .TwoColumns {
  display: flex;
  justify-content: space-between;
}
.DesktopForgottenPassword .RecoverySteps > .TwoColumns > * {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
