.PaymentManagementView {
    --light-grey-payment-view: #d0cece;
}

.PaymentManagementView .AddFirstPaymentMethodContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 250px;
    background-color: var(--white-color);
    border: solid 1px var(--light-grey-payment-view);
    border-radius: 25px;
    margin-top: 20px;
    padding: 10px;
}

.PaymentManagementView .ButtonAddPaymentMethod {
    max-width: 400px;
}

.PaymentManagementView .AlreadyPresentPaymentMethodContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 250px;
    max-width: 700px;
    margin-top: 20px;
}

.PaymentManagementView .PreviousPaymentMethodContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    background-color: var(--white-color);
    border-radius: 25px;
    border: solid 1px var(--light-grey-payment-view);
    color: var(--light-grey-payment-view);
    padding: 30px;
}

.PaymentManagementView .ChangePaymentMethodButton {
    max-width: 200px;
    margin-top: 20px;
}

.PaymentManagementView .PaymentMethodDetailsContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    color: black;
}

.PaymentManagementView .PaymentMethodDetailsItem {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 15px;
}

.PaymentManagementView .InputPaymentDetails {
    color: black;
    margin-top: 5px;
    cursor: not-allowed;
}

.PaymentManagementView .InputPaymentDetails {
    color: black;
    margin-top: 5px;
    cursor: not-allowed;
    width: 250px;
}

.PaymentManagementView .PaymentMethodExpirationDateRow >input {
    max-width: 115px;
}

.PaymentManagementView .paymentType {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fafafa;
    border-radius: 15px;
    border: solid 1px var(--light-grey-payment-view);
    max-width: 60px;
    margin-top: 5px;
    text-transform: uppercase;
}

.PaymentMethodContainer .PaymentInfo {
    display: flex;
    align-items: center;
    color: var(--action-primary-color);
    margin-top: 20px;
    line-height: 1em;
}
