.PaymentMethodPopup .top {
    display: none;
}

.ReviewPayment .ListItemContainer {
    display: flex;
    align-items: center;
}

.PaymentMethodPopup .PaymentMethodContainer {
    border: 1px solid var(--light-grey);
    border-radius: 10px;
    padding: 20px;
    background-color: white;
    margin: 20px 0 40px 0;
}

.PaymentMethodPopup .PaymentMethodContainer .ant-radio-group {
    width: 100%;
}

.ReviewPayment .ListIcon {
    color: #52AF61;
    margin-top: 2px;
    margin-right: 13px;
}

.PaymentMethodPopup h3 {
    color: var(--action-primary-color);
    text-align: center;
}

.PaymentMethodPopup h4 {
    color: var(--action-primary-color);
    display: flex;
    width: 100%;
    align-items: center;
    margin-bottom: 0.5em;
    margin-top: 0.5em;
}

.PaymentMethodPopup h4::after {
    content: '';
    border-bottom: 1px solid var(--action-primary-color);
    height: 0;
    margin-left: 8px;
    display: inline-block;
    flex-grow: 10;
}

.PaymentMethodPopup h4::before {
    content: '';
    border-bottom: 1px solid var(--action-primary-color);
    height: 0;
    margin-right: 8px;
    display: inline-block;
    flex-grow: 1;
}

.PaymentMethodPopup .PaymentInfo {
    display: flex;
    align-items: center;
    color: var(--action-primary-color);
    margin-top: 20px;
    line-height: 1em;
}

.PaymentMethodPopup .PaymentInfo .anticon-info-circle{
    color: var(--action-primary-color);
    margin-right: 12px;
    font-size: 18px;
}

.PaymentMethodPopup .defaultPayment {
    color: var(--grey);
}
