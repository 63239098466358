.DesktopLogin .LoginStepFirst {
  width: 400px;
  margin: 16px;
}

.DesktopLogin .LoginStepFirst > .TwoColumns {
  display: flex;
  justify-content: space-between;
}
.DesktopLogin .LoginStepFirst > .TwoColumns > * {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.DesktopLogin .MobileMarketplaceLink {
  margin-top: 40px;
  display: flex;
  justify-content: space-evenly;
}
