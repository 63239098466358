.AirRenewalView .SectionContent {
    padding: 0;
    text-align: center;
    position: relative;
}

.AirRenewalView header {
    background-color: #EFEFEF;
    padding: 8px;
    margin-bottom: 24px;
}

.AirRenewalView header h2,
.AirRenewalView header h3
{
    margin-bottom: 0;
    color: var(--action-brand-color);
}

.AirRenewalView header h2 {
    font-weight: bold;
}

.AirRenewalView .RenewalPrice {
    margin-left: 8px;
    text-align: left;
    margin-bottom: 24px ;
}

.AirRenewalView .RenewalPrice h2{
    color: var(--dark-grey);
    margin-bottom: 0;
    font-size: 2em;
    font-weight: bold;
}

.AirRenewalView .RenewalPrice h2 .taxes{
    font-size: 14px;
    font-weight: normal;
}

.AirRenewalView .RenewalPrice h3{
    color: var(--dark-grey);
    margin-bottom: 0;
    font-size: 1.2em;
    line-height: 0.7em;
    font-weight: bold;
}

.AirRenewalView .RenewalFeatures {
    margin: auto;
    display: table;
}

.AirRenewalView .ListItemContainer {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    margin-bottom: 8px;
    font-size: 16px;
    font-weight: 600;
}

.AirRenewalView .ListItemContainer .ListIcon {
    font-weight: bold;
    color: var(--action-brand-color);
    margin-right: 8px;
}

.AirRenewalView .ReviewFooter {
    padding-top: 32px;
}

.desktop .AirRenewalView .ReviewFooter {
    padding: 32px 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.AirRenewalView .ReviewFooter .ReviewInfo {
    color: var(--dark-grey);
    font-weight: 600;
    font-size: 0.95em;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.desktop .AirRenewalView .ReviewFooter .ReviewInfo {
    margin-right: 16px;
}

.small .AirRenewalView .ReviewFooter .ReviewInfo,
.extrasmall .AirRenewalView .ReviewFooter .ReviewInfo {
    margin-bottom: 16px;
}

.AirRenewalView .ReviewFooter .ReviewInfo .ReviewFooterIcon {
    color: #FB8680;
    margin-right: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.desktop .AirRenewalView .ReviewFooter .MyPageButton {
    width: 200px;
}

.RenewalPlanSelection {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 40px;
}

.desktop .RenewalPlanSelection {
    flex-direction: row;
}

.RenewalPlan {
    display: flex;
    align-items: center;
}

.AirRenewalView .DiscountPrice {
    position: absolute;
    margin-top: -20px;
    color: #f8837e;
    font-weight: bold;
    font-size: 1.4em;
}

.AirRenewalView .StrikeThroughPrice {
    text-decoration: line-through;
    text-decoration-thickness: 2px;
}

.AirRenewalView .DiscountedBadge {
    z-index: 2;
    background-color: #f8837e;
    color: #fff;
    transform: rotate(50deg);
    margin-top: 15px;
    margin-right: -35px;
    width: 130px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    font-size: 16px;
    letter-spacing: -0.5px;
    font-weight: 600;
    text-transform: uppercase;
}

.AirRenewalView .DiscountedBadgeContainer {
    position: absolute;
    display: flex;
    justify-content: flex-end;
    width: 100%;
}
