.DocumentsView .SubPageNavbar {
  margin-bottom: 56px;
}

.extrasmall .DocumentsView .SubPageNavbar,
.small .DocumentsView .SubPageNavbar {
  margin-bottom: 40px;
}

.App.desktop .DocumentsView .SectionContent {
  display: flex;
}

.App.desktop .DocumentsView .SectionContent > * {
  flex: 1 0 0;
}

.ant-modal.EmbeddedDocumentModal {
  border-radius: 8px;
  background-color: #ffffff;
  position: relative;
  padding: 0;
}

.ant-modal.EmbeddedDocumentModal.AirModal .top {
  display: none;
}

.ant-modal.EmbeddedDocumentModal .title {
  height: 32px;
  color: var(--black-color);
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.7px;
  line-height: 32px;
}

.ant-modal.EmbeddedDocumentModal .content {
  font-size: 10px;
  padding: 10px;
}

.PrivacyUpdate .MyPageButton {
  margin: 8px;
}

.desktop.PrivacyUpdate .MyPageButton {
  width: initial;
}

.PrivacyUpdate .PrivacyTitle {
  color: var(--black-color);
  font-weight: 600;
  letter-spacing: -0.7px;
  line-height: 32px;
}

.PrivacyUpdate .Subtitle {
  opacity: 0.4;
  font-size: 16px;
  color: var(--black-color);
  font-weight: 400;
  letter-spacing: -0.6px;
  margin-bottom: 20px;
}

.PrivacyUpdate {
  color: var(--black-color);
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.4px;
}


.PrivacyUpdate .AgreementItem {
  opacity: 0.6;
}

.DocumentsView .FilterRow {
  margin-bottom: 18px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.DocumentsView .FilterRow.mobile {
  margin-bottom: 18px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.DocumentsView .FilterRow.mobile > * {
  width: 100% !important;
  padding-bottom: 10px;
}

.AgendaTable td.column-actions.document-buttons a {
  color: rgba(0, 0, 0, 0.65) !important;
  text-decoration: underline !important;
}

.align-right {
  float: right;
  clear: both;
}

.desktop.CustomDocumentFields {
  display: flex;
  flex-direction: row;
}

.desktop.CustomDocumentFields > * {
  width: 0;
  flex-grow: 1;
  margin: 20px 20px;
}

.CustomDocumentFields > * {
  margin: 20px 0;
}

.desktop.CustomDocumentButtons {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.desktop.CustomDocumentButtons > *:nth-child(2) {
  margin-left: 20px;
}

.CustomDocumentButtons .MyPageButton {
  margin-bottom: 20px;
}

.desktop.CustomDocumentButtons .MyPageButton {
  max-width: 150px;
}

.document-file-upload {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  letter-spacing: -0.2px;
  line-height: 20px;
  cursor: pointer;
  width: 100%;
  border: none;
  border-radius: 16px;
  background-color: var(--action-primary-color);
  color: white;
  height: 32px;
  max-width: 150px;
}

.document-file-upload input[type="file"] {
  width: 0;
}

.AddDocumentButton {
  margin: 10px;
  color: var(--action-primary-color);
}

.DocumentsView .document-buttons {
  color: var(--action-info-color);
}
