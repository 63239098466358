.SupportRegioneLombardiaView {}

.SupportRegioneLombardiaView.desktop {
    width: 288px;
    height: 320px;
    border-radius: 16px;
    border: 1px solid var(--grey-semi-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    background-color: var(--white-color);
}

.SupportRegioneLombardiaView.desktop .title {
    color: var(--black-color);
    font-size: 24px;
    font-weight: 600;
    letter-spacing: -0.2px;
    line-height: 36px;
    margin-top: 20px;
}

.SupportRegioneLombardiaView.desktop .subtitle {
    width: 264px;
    height: 59px;
    opacity: 0.4;
    color: var(--black-color);
    font-family: "Work Sans";
    font-size: 13px;
    font-weight: 400;
    letter-spacing: -0.32px;
    line-height: 19.2px;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.SupportRegioneLombardiaView.desktop .numbers {
    display: flex;
    align-content: center;
}

.SupportRegioneLombardiaView.desktop .bubble {
    width: 103px;
    height: 103px;
    background-color: var(--action-primary-color);
    border-radius: 51px;
    margin-top: 25px;
}

.SupportRegioneLombardiaView.desktop .numbers .mobile {
    width: 206px;
    height: 33px;
    border-radius: 0 8px 8px 0;
    background-color: var(--action-primary-color);
    color: var(--white-color);
    font-size: 16px;
    display: flex;
    padding: 5px 5px;
    justify-content: center;
    align-content: center;
}

.SupportRegioneLombardiaView.desktop .numbers .mobileNumber {
    display: flex;
}

.SupportRegioneLombardiaView.desktop .numbers .phoneIcon {
    width: 33px;
    height: 33px;
    border-radius: 8px 0 0 8px;
    background-color:var(--action-primary-color);
    color: var(--white-color);
    padding-top: 8px;
    padding-left: 15px;
}

.SupportRegioneLombardiaView.extrasmall,
.SupportRegioneLombardiaView.small {
    width: 288px;
    height: 320px;
    border-radius: 16px;
    border: 1px solid var(--grey-semi-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    background-color: var(--white-color);
}

.SupportRegioneLombardiaView.extrasmall .title ,
.SupportRegioneLombardiaView.small .title {
    color: var(--black-color);
    font-size: 24px;
    font-weight: 600;
    letter-spacing: -0.2px;
    line-height: 36px;
    margin-top: 20px;
}

.SupportRegioneLombardiaView.extrasmall .subtitle ,
.SupportRegioneLombardiaView.small .subtitle {
    width: 264px;
    height: 59px;
    opacity: 0.4;
    color: var(--black-color);
    font-family: "Work Sans";
    font-size: 13px;
    font-weight: 400;
    letter-spacing: -0.32px;
    line-height: 19.2px;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.SupportRegioneLombardiaView.extrasmall .numbers,
.SupportRegioneLombardiaView.small .numbers {
    display: flex;
    align-content: center;
}

.SupportRegioneLombardiaView.extrasmall .bubble,
.SupportRegioneLombardiaView.small .bubble {
    width: 103px;
    height: 103px;
    background-color: var(--action-primary-color);
    border-radius: 51px;
    margin-top: 25px;
}

.SupportRegioneLombardiaView.extrasmall .numbers .mobile ,
.SupportRegioneLombardiaView.small .numbers .mobile {
    width: 206px;
    height: 33px;
    border-radius: 0 8px 8px 0;
    background-color: var(--action-primary-color);
    color: var(--white-color);
    font-size: 16px;
    display: flex;
    padding: 5px 5px;
    justify-content: center;
    align-content: center;
}

.SupportRegioneLombardiaView.extrasmall .numbers .mobileNumber ,
.SupportRegioneLombardiaView.small .numbers .mobileNumber {
    display: flex;
}

.SupportRegioneLombardiaView.extrasmall .numbers .phoneIcon ,
.SupportRegioneLombardiaView.small .numbers .phoneIcon {
    width: 33px;
    height: 33px;
    border-radius: 8px 0 0 8px;
    background-color:var(--action-primary-color);
    color: var(--white-color);
    padding-top: 8px;
    padding-left: 15px;
}