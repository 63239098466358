.ProfileViewForm {
}

.ProfileViewForm .WelcomeMessage.ant-alert.ant-alert-info {
    background-color: white;
    border: none;
    border-radius: 8px;
    padding: 16px 24px;
    margin-bottom: 40px;
    color: #191d3a;
}

.ProfileViewForm .WelcomeMessage.ant-alert.ant-alert-info .ant-alert-message {
    font-size: 22px;
    font-weight: 600;
    letter-spacing: -0.7px;
    line-height: 32px;
    margin-bottom: 4px;
}

.ProfileViewForm .WelcomeMessage.ant-alert.ant-alert-info .ant-alert-description {
    opacity: 0.4;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -0.4px;
    line-height: 24px;
    margin: 0;
}

@media (max-width: 840px) {
    .ProfileViewForm .ant-col {
        width: 100%;
    }
}

.ProfileViewForm .SmallDivision {
    margin-bottom: 40px;
}

.small .ProfileViewForm .SmallDivision,
.extrasmall .ProfileViewForm .SmallDivision {
    margin-bottom: 16px;
}

.ProfileViewForm .ProfileAlert {
    margin-bottom: 20px;
}
