.MyPageCheckbox {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.4px;
  line-height: 24px;
  display: flex;
}

.MyPageCheckbox > * {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.MyPageCheckbox > *:first-child {
  margin-right: 12px;
}
