.ThankYouPageView .ThankYouPageViewInner {
  color: var(--black-color);
  border: 1px solid var(--grey-semi-color);
  background-color: white;
  border-radius: 8px;
  margin-top: 40px;
  padding: 20px;
}

.ThankYouPageView .textSuccessfulPayment {
  color: var(--green);
}

.ThankYouPageView .textGoToHome {
  cursor: pointer;
  color: var(--action-info-color);
}

.ThankYouPageView .textGoToSupport {
  cursor: pointer;
  color: var(--action-warning-color);
}

