.AgendaView .SmallDivision {
    text-align: center;
    background-color: var(--action-primary-color);
    border-radius: 8px;
}

.AgendaView .SectionContent {
    padding: 0;
}

.AgendaTable {
    overflow: scroll;
}

.AgendaTable .ant-table-thead > tr > th {
    background-color: var(--action-info-color);
    border: none;
    color: #000;
}

.AgendaTable .ant-table-thead > tr:first-child > th:first-child{
    border-radius: 8px 0 0 8px;
    overflow: hidden;
}

.AgendaTable .ant-table-thead > tr:first-child > th:last-child {
    border-radius: 0 8px 8px 0;
    overflow: hidden;
}

.AgendaTable .ant-table-tbody > tr > td {
    border: none;
}

.AgendaTable .MyPageButton {
    max-width: 150px;
    margin: 0 auto;
}

.AgendaTable th.column-actions,
.AgendaTable td.column-actions {
    text-align: center !important;
}

.AgendaTable td.column-actions button {
    border: none;
    background-color: transparent !important;
    color: rgba(0, 0, 0, 0.65) !important;
    text-decoration: underline !important;
}

.AgendaTable td.column-actions button.disabled {
    text-decoration: none !important;
}

.AgendaView .AgendaControls {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 24px 16px;
    color: var(--action-primary-color);
}

.AgendaView .AgendaControls.mobile {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    color: var(--action-primary-color);
}

.AgendaView .AgendaControls.mobile > * {
    width: 100% !important;
    padding-bottom: 10px;
}

.align-right {
    float: right;
    clear: both;
}

.AgendaView .AgendaControls .History > a {
    text-decoration: solid underline var(--action-primary-color);
}

.AgendaModal .top {
    display: none;
}

.AgendaModal h2, .AgendaModal div{
    text-align: center;
}

.AgendaModal .Content {
    padding-top: 20px;
}

.AgendaModal .Content > * {
    max-width: 200px;
}

.AgendaModal .CalendarContent {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.AgendaModal .Content .MyPageButton,
.AgendaModal .CalendarContent .MyPageButton {
    margin: 20px auto 0 auto;
}
