.zzz_ZuoraPayment {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 5%;
}

.zzz_ZuoraPayment #z-container {
  margin-left: -150px;
  left: auto;
}

.ZuoraPayment {
  border-radius: 8px;
  overflow: hidden;
}

.small .InstallerList .SumbmitButton,
.extrasmall .InstallerList .SumbmitButton {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
}

.HeaderTwo {
  color: var(--black-color);
  font-size: 17px;
  font-weight: 600;
  letter-spacing: -0.52px;
  margin-top: 40px;
  margin-bottom: 10px;
}

.HeaderTwo-lg {
  color: var(--black-color);
  font-size: 22px;
  font-weight: 600;
  letter-spacing: -0.7px;
  margin-top: 56px;
  margin-bottom: 20px;
}

.InstallerPaymentView {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.4px;
  line-height: 24px;
}

.InstallerPaymentView .MyPageCheckbox {
  margin: 10px auto;
}

.InstallerPaymentView .PaymentErrorMessage {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.4px;
  line-height: 24px;
  color: var(--action-danger-color);
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.InstallerPaymentView .PaymentErrorMessage > *:first-child {
  margin-right: 10px;
}

.SlimpayIframeContainer {
  max-width: 600px;
  margin: auto;
}

.SlimpayIframe {
  padding: 0;
  border: none;
  margin: 0;
  margin-left: -5px;
  height: 510px;
}

.small .SlimpayIframe,
.extrasmall .SlimpayIframe {
  height: 600px;
}

.AirClubInfoContract{
  margin-top: 20px;
  display: flex;
  justify-content: flex-start;
}
.AirClubInfoContract a {
  font-size: 16px;
  color: var(--black-color);
  text-decoration: none;
}

.FriendlyReminder {
  width: 350px;
  margin-left: auto;
  margin-right: auto;
  padding: 16px;
  background-color: white;
  border-radius: 8px;
  font-size: 14px;
}