.PersonalDocumentsView {
}

@media (min-width: 840px) {
  .PersonalDocumentsView {
  }

  .PersonalDocumentsView .FieldsRow {
    display: flex;
  }

  .PersonalDocumentsView .FieldsRow > * {
    flex: 1 0 0;
  }

  .PersonalDocumentsView .FieldsRow > *:not(:first-child) {
    margin-left: 24px;
  }

  .ButtonContainer {
    margin-top: 3vh;
    margin-bottom: 3vh;
    display: flex;
    justify-content: space-evenly;
  }
}

.LicenseTypeSelector {
  color: var(--black-color);
  font-size: 14px;
  letter-spacing: -0.2px;
  line-height: 20px;
}

.LicenseTypeSelector .ant-select-selection {
  background-color: white;
  border-radius: 8px;
  color: var(--black-color);
  font-size: 16px;
  letter-spacing: -0.4px;
  line-height: 24px;
  padding: 0 16px;
}

.PersonalDocumentsView .buttonCenter {
  display: flex;
  justify-content: center;
}

.UploadButton {
  min-width: 100%;
}

.ant-upload.ant-upload-select{
  min-width: 100%;
}
