.ProductCard {
    position: relative;
    background: white;
    border-radius: 12px;
    width: 270px;
    height: 380px;
    overflow: hidden;
    margin: 16px auto;
    display: flex;
    flex-direction: column;
    border: 1px solid var(--grey-semi-color);
}

.ProductCard img {
    width: 100%;
    height: auto;
}

.ProductCard .CardContent {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: flex-end;
    background-color: white;
    padding: 16px 24px 48px 24px;
}

.ProductCard .TextContainer {
    flex-grow: 1;
    position: relative;
}

.ProductCard .SubscribeButton {
    width: 100%;
    border-radius: 8px;
    border: none;
    height: 32px;
    background-color: var(--action-primary-color);
    color: white;
}

.ProductCard h2, .ProductCard p {
    margin-bottom: 8px;
}

.ProductCard h2 {
    color: var(--black-color);
    font-size: 22px;
    font-weight: 600;
    letter-spacing: -0.7px;
    line-height: 32px;
}


.ProductCard .InfoIcon {
    color: #4C96F6;
    position: absolute;
    right: -4px;
    top: 4px;
    cursor: pointer;
}

.CardBack {
    position: absolute;
    display: flex;
    flex-direction: column;
    bottom: 0;
    background-color: white;
    width: 100%;
    height: 100%;
    transition: all .5s ease-out;
}

.CardBack.closed {
    height: 32px;
}

.ProductCard .CardBackHeader {
    cursor: pointer;
    position: relative;
    text-align: center;
    font-weight: 600;
    padding: 6px 24px;
    height: 32px;
    background-color: #D2F4ED;
    color: var(--grey-dark-color);
}

.CardBack .TextContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.ProductCard .BackIcon {
    position: absolute;
    right: 40px;
    top: 10px;
    cursor: pointer;
}

.ProductCard .AirClubInfoIcon {
    position: absolute;
    right: 24px;
    top: 40px;
    cursor: pointer;
}

.ProductCard .ListItemContainer {
    display: flex;
}

.ProductCard .ListIcon {
    color: var(--action-primary-color);
    margin-top: 2px;
    margin-right: 13px;
}

.ProductCard .Description,
.ProductCard .Price {
    color: var(--black-color);
}

.ProductCard .Available .Price {
    margin-bottom: 16px;
    text-align: center;
}

.ProductCard .Expiring .Price {
    color: var(--grey-dark-color);
    display: flex;
    justify-content: space-between;
}

.ProductCard .ActiveLabel {
    color: #3FDC17;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 16px;
}

.ProductCard .ExpiringLabel {
    color: var(--action-warning-color);
}

.ProductCard .Expiring .ActiveLabel,
.ProductCard .Expiring .ExpiringLabel {
    margin-bottom: 0;
}

.ProductCard .DiscountedBadge {
    position: absolute;
    z-index: 3;
    background-color: #f8837e;
    color: #fff;
    transform: rotate(-45deg);
    margin-top: 15px;
    margin-left: -35px;
    width: 130px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    font-size: 16px;
    letter-spacing: -0.5px;
    font-weight: 600;
}

.ProductCard .PromoPrice {
    color: #f8837e;
    font-weight: bold;
}
