.SuggestedAdditionalServices h2 {
    color: var(--grey-dark-color);
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 0;
}

.SuggestedAdditionalServices h3 {
    color: var(--grey-dark-color);
    font-weight: bold;
    font-size: 1.5em;
    margin-bottom: 1em;
}

.SuggestedAdditionalServicesInfo {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 30px;
    background-color: #12548A;
    color: white;
}

.small .SuggestedAdditionalServicesInfo, .extrasmall .SuggestedAdditionalServicesInfo {
    flex-wrap: wrap;
    justify-content: center;
}

.small .SuggestedAdditionalServicesInfo>*, .extrasmall .SuggestedAdditionalServicesInfo>* {
    margin-top: 32px;
}

.small .InfoColumn, .extrasmall .InfoColumn {
    width: 100%;
}

.desktop .SuggestedAdditionalServicesInfo {
    min-height: 450px;
    font-size: 16px;
}

.SuggestedAdditionalServicesInfo h4 {
    font-size: 1.2em;
    text-transform: uppercase;
    color: #F6CF02;
}

.SuggestedAdditionalServicesInfo .InfoColumn {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.SuggestedAdditionalServicesInfo .AxaLogoContainer {
    margin-top: 24px;
    font-size: 12px;
    text-align: right;
}

.SuggestedAdditionalServicesInfo .AxaLogoContainer img {
    width: 40px;
    height: auto;
    vertical-align: baseline;
}

.SuggestedAdditionalServicesSelectionContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 24px;
    margin-bottom: 24px;
    font-size: 18px;
}

.SuggestedAdditionalServicesSelection {
    display: flex;
}

.SuggestedAdditionalServicesSelection p {
    margin-right: 16px;
}

.SuggestedAdditionalServicesSelection .ant-checkbox-wrapper {
    margin-left: 12px;
    font-size: 18px;
}

.ProtectionLevel {
    max-width: 500px;
    text-align: center;
    border: 3px solid #F6CF02;
    padding: 8px 40px;
    border-radius: 4px;
    margin-top: 40px;
}

.ProtectionLevel>div {
    padding: 8px 0;
}

.ProtectionLevel img {
    width: 40px;
    height: auto;
}

.ProtectionRow {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.ProtectionBar>div {
    height: 8px;
    flex-grow: 1;
    background-color: #7EDA57;
    margin: 0 4px;
    border-radius: 2px;
}

.AirClubPrice {
    color: var(--action-primary-color);
}

.SuggestedAdditionalServicesPrivacyCheck {
    margin-right: 8px;
}

@media (min-width: 736px) {
    .ThreeColumnView {
        display: flex;
        flex-direction: row;
    }

    .OneColumnComponent {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }
}

@media (max-width: 736px) {
    .ThreeColumnView {
        display: flex;
        flex-direction: column;
        align-content: center;
    }

    .OneColumnComponent {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }
}

.SuggestedAdditionalServicesSelectionTitle {
    font-family: Poppins-Regular, sans-serif;
    font-weight: bold;
    text-align: center;
    font-size: 24px;
    color: #223239;
}

.SuggestedAdditionalServicesTitleBrandColor {
    text-transform: uppercase;
    color: var(--action-brand-color);
}

.SuggestedAdditionalServicesSubTitleRedColor {
    color: #FB7670;
}

.SuggestedAdditionalServicesSubTitle {
    font-family: Poppins-Regular, sans-serif;
    font-weight: 400;
    text-align: center;
    font-size: 18px;
    color: #223239;
}

.SvgImageContainer {
    margin: 20px 0;
    display: flex;
    justify-content: center;
}

.SuggestedAdditionalServicesFeatureCardContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    background: #FFFFFF;
    box-shadow: 0 5px 20px -5px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    min-height: 80px;
    margin: 15px 30px;
    padding: 0 5px 0 5px;
}

.SuggestedAdditionalServicesFeatureCardContainer :nth-child(1) { flex: 1; }
.SuggestedAdditionalServicesFeatureCardContainer :nth-child(2) { flex: 3; }
.SuggestedAdditionalServicesFeatureCardContainer :nth-child(3) { flex: 1; }

.SuggestedAdditionalServicesFeatureOneColumnComponent {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    margin-top: 15px;
}

.SuggestedAdditionalServicesIconContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--SuggestedAdditionalServices-icon-container-color);
    border-radius: 12px;
    max-width: 46px;
    width: 46px;
    max-height: 46px;
    height: 46px;
}

.SuggestedAdditionalServicesIconColor {
    color: var(--SuggestedAdditionalServices-icon-color);
}

.SuggestedAdditionalServicesFeatureDescriptionContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.SuggestedAdditionalServicesColumnTitle {
    font-family: Poppins-Regular, sans-serif;
    font-size: 15px;
    font-weight: 700;
    line-height: 25px;
    text-align: center;
}

.SuggestedAdditionalServicesColumnFeature {
    font-family: Poppins-Regular, sans-serif;
    font-size: 13px;
    font-weight: 400;
    line-height: 25px;
    text-align: center;
}

.AxaIconContainer {
    border-radius: 8px;
    overflow: hidden;
    max-width: 30px;
    width: 30px;
    max-height: 30px;
    height: 30px;
}

.SuggestedAdditionalServicesProductPlanSelection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
}

.SuggestedAdditionalServicesBuyButton {
    max-width: 335px;
    min-height: 55px;
    height: 55px;
    font-size: 15px;
    font-weight: 600;
    text-align: center;
}

.SuggestedAdditionalServicesDontBuyButton {
    width: 104px;
    min-height: 29px;
    height: 29px;
    font-size: 15px;
    font-weight: 600;
    text-align: center;
}

.SuggestedAdditionalServicesProceedButtonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.SuggestedAdditionalServicesProceedButtonsMobileContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.SuggestedAdditionalServicesDontBuyMobileButton {
    min-height: 55px;
    height: 55px;
    max-width: 104px;
    font-size: 15px;
    font-weight: 600;
    text-align: center;
    white-space: normal;
}

.generalConditionsCheckboxContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.InformativeNotesContract{
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;
}
.InformativeNotesContract a {
    font-size: 16px;
    color: var(--black-color);
    text-decoration: none;
}
