.VehicleSelector.MyPageSelector{
    margin-bottom: 56px;
}

.extrasmall .VehicleSelector.MyPageSelector,
.small .VehicleSelector.MyPageSelector {
    margin-bottom: 40px;
}


.VehicleSelector.MyPageSelector .ant-select.ant-select-enabled.ant-select-no-arrow {
    overflow: hidden;
}

.VehicleSelector.MyPageSelector .ant-select-selection-selected-value {
    text-overflow: ellipsis;
}
