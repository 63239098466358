.AuthMobileContainer {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: var(--black-color);
  font-size: 16px;
}

.AuthMobileContainer > * {
  display: flex;
  justify-content: center;
}
