.MobileLogin .LoginStepFirst {
  flex-grow: 1;
  margin: 16px;
}

.MobileLogin .LoginStepFirst > .TwoColumns {
  display: flex;
  justify-content: space-between;
}
.MobileLogin .LoginStepFirst > .TwoColumns > * {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.MobileLogin .MobileMarketplaceLink {
  margin: 40px 0;
  display: flex;
  justify-content: space-evenly;
}
