.SupportHelpDeskView {}

.SupportHelpDeskView a{
    color: blue;
    opacity: 1;
}

.AxaLogo {
    border-radius:50%;
    max-width: 100px;
    margin-top: 20px;
}

.subtitle {
    margin-bottom: 15px;
}

.SupportHelpDeskView.desktop {
    width: 288px;
    height: 320px;
    border-radius: 16px;
    border: 1px solid var(--grey-semi-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    background-color: var(--white-color);
}

.SupportContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.SupportContainerMobile {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.SupportHelpDeskView.desktop .title {
    color: var(--black-color);
    font-size: 24px;
    font-weight: 600;
    letter-spacing: -0.2px;
    line-height: 36px;
    margin-top: 20px;
}

.SupportHelpDeskView.desktop .subtitle {
    width: 264px;
    height: 59px;
    opacity: 0.6;
    color: var(--black-color);
    font-family: "Work Sans";
    font-size: 13px;
    font-weight: 400;
    letter-spacing: -0.32px;
    line-height: 19.2px;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.SupportHelpDeskView.desktop .numbers {
    display: flex;
    align-content: center;
}

.SupportHelpDeskView.desktop .bubble {
    width: 103px;
    height: 103px;
    background-color: var(--action-primary-color);
    border-radius: 51px;
    margin-top: 25px;
}

.SupportHelpDeskView.desktop .numbers .mobile {
    width: 206px;
    height: 33px;
    border-radius: 0 8px 8px 0;
    background-color: var(--action-primary-color);
    color: var(--white-color);
    font-size: 16px;
    display: flex;
    padding: 5px 5px;
    justify-content: center;
    align-content: center;
    cursor: pointer;
}

.SupportHelpDeskView.desktop .numbers .mobileNumber {
    display: flex;
}

.SupportHelpDeskView.desktop .numbers .phoneIcon {
    width: 33px;
    height: 33px;
    border-radius: 8px 0 0 8px;
    background-color:var(--action-primary-color);
    color: var(--white-color);
    padding-top: 8px;
    padding-left: 15px;
    cursor: pointer;
}

.SupportHelpDeskView.extrasmall,
.SupportHelpDeskView.small {
    width: 288px;
    height: 320px;
    border-radius: 16px;
    border: 1px solid var(--grey-semi-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    background-color: var(--white-color);
    margin-top: 30px;
}

.SupportHelpDeskView.extrasmall .title ,
.SupportHelpDeskView.small .title {
    color: var(--black-color);
    font-size: 24px;
    font-weight: 600;
    letter-spacing: -0.2px;
    line-height: 36px;
    margin-top: 20px;
}

.SupportHelpDeskView.extrasmall .subtitle ,
.SupportHelpDeskView.small .subtitle {
    width: 264px;
    height: 59px;
    opacity: 0.6;
    color: var(--black-color);
    font-family: "Work Sans";
    font-size: 13px;
    font-weight: 400;
    letter-spacing: -0.32px;
    line-height: 19.2px;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.SupportHelpDeskView.extrasmall .numbers,
.SupportHelpDeskView.small .numbers {
    display: flex;
    align-content: center;
}

.SupportHelpDeskView.extrasmall .bubble,
.SupportHelpDeskView.small .bubble {
    width: 103px;
    height: 103px;
    background-color: var(--action-primary-color);
    border-radius: 51px;
    margin-top: 25px;
}

.SupportHelpDeskView.extrasmall .numbers .mobile ,
.SupportHelpDeskView.small .numbers .mobile {
    width: 206px;
    height: 33px;
    border-radius: 0 8px 8px 0;
    background-color: var(--action-primary-color);
    color: var(--white-color);
    font-size: 16px;
    display: flex;
    padding: 5px 5px;
    justify-content: center;
    align-content: center;
}

.SupportHelpDeskView.extrasmall .numbers .mobileNumber ,
.SupportHelpDeskView.small .numbers .mobileNumber {
    display: flex;
}

.SupportHelpDeskView.extrasmall .numbers .phoneIcon ,
.SupportHelpDeskView.small .numbers .phoneIcon {
    width: 33px;
    height: 33px;
    border-radius: 8px 0 0 8px;
    background-color:var(--action-primary-color);
    color: var(--white-color);
    padding-top: 8px;
    padding-left: 15px;
}

.days {
    max-width: 210px;
}

.ContactContainer {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    justify-items: center;
    align-items: center;
    opacity: 0.8;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 25px;
}

a:link{
    font-size: 20px;
    color: darkviolet;
    text-decoration: none;
}

.AxaContactContainer {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    justify-items: center;
    align-items: center;
    opacity: 0.8;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 20px;
}