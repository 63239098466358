.AuthDesktopContainer {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  display: flex;
  justify-content: space-between;
  color: var(--black-color);
  font-size: 16px;
}

.AuthDesktopContainer .LoginVertical {
  flex-grow: 1;
  flex-basis: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.AuthDesktopContainer .ImageLayer {
  background-image: url(../../../common/images/LoginLayer.png);
  background-size: cover;
  background-position: center center;
  color: var(--action-primary-color);
}

.AuthDesktopContainer .FormLayer {
  background-color: var(--magic1-color);
}

.AuthDesktopContainer .LoginVertical > *:nth-child(2) {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
