.desktop .OptionalServicesView .SectionContent {
  padding: 30px;
  position: relative;
}

.OptionalServicesView .ServiceDetailContainer small {
  color: var(--grey-dark-color);
}

.OptionalService h2 {
  color: var(--grey-dark-color);
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 0;
}
.BannerMoVeInRenewal {
  border-radius: 8px;
  padding: 16px;
  color: var(--secondary-color);
  background-color: var(--light-success-color);
  font-weight: bold;
  text-align: center;
  margin-bottom: 3px;
}

.OptionalService h3 {
  color: var(--grey-dark-color);
  font-weight: bold;
  font-size: 1.5em;
  margin-bottom: 1em;
}

.OptionalServicesView .NextButton {
  margin: 20px 0 20px auto;
  width: auto;
}

.OptionalServicesView .ant-radio-wrapper {
  margin-right: 0;
}

.OptionalServiceInfo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 30px;
  background-color: #12548A;
  color: white;
}

.small .OptionalServiceInfo, .extrasmall .OptionalServiceInfo {
  flex-wrap: wrap;
  justify-content: center;
}

.small .OptionalServiceInfo>*, .extrasmall .OptionalServiceInfo>* {
  margin-top: 32px;
}

.small .InfoColumn, .extrasmall .InfoColumn {
  width: 100%;
}

.desktop .OptionalServiceInfo {
  min-height: 450px;
  font-size: 16px;
}

.OptionalServicesView .ListItemContainer {
  display: flex;
}

.OptionalServicesView .ListIcon {
  color: #F6CF02;
  margin-top: 2px;
  margin-right: 13px;
}

.OptionalServiceInfo h4 {
  font-size: 1.2em;
  text-transform: uppercase;
  color: #F6CF02;
}

.OptionalServiceInfo .InfoColumn {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.OptionalServiceInfo .AxaLogoContainer {
  margin-top: 24px;
  font-size: 12px;
  text-align: right;
}

.OptionalServiceInfo .AxaLogoContainer img {
  width: 40px;
  height: auto;
  vertical-align: baseline;
}

.OptionalServicesView .ServiceInfoIcon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.small .OptionalServicesView .ServiceInfoIcon,
.extrasmall .OptionalServicesView .ServiceInfoIcon{
  order: -1;
}

.OptionalServicesView .ServiceInfoIcon img {
  max-width: 200px;
  height: auto;
}

.OptionalServiceSelectionContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 24px;
  margin-bottom: 24px;
  font-size: 18px;
}

.OptionalServiceSelection {
  display: flex;
}

.OptionalServiceSelection p {
  margin-right: 16px;
}

.OptionalServiceSelection .ant-checkbox-wrapper {
  margin-left: 12px;
  font-size: 18px;
}

.OptionalServicesView .OptionalServicePrivacy {
  font-size: 14px;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--grey-dark-color);
  margin-bottom: 20px;
}

.OptionalServicesView .OptionalServicePrivacy p {
  margin: 0;
}

.OptionalServicesView .OptionalServicePrivacy h4, h4.PrivacyHeadTitle {
  font-size: 16px;
  margin-bottom: 0;
}

.ProtectionLevel {
  max-width: 500px;
  text-align: center;
  border: 3px solid #F6CF02;
  padding: 8px 40px;
  border-radius: 4px;
  margin-top: 40px;
}

.ProtectionLevel>div {
  padding: 8px 0;
}

.ProtectionLevel img {
  width: 40px;
  height: auto;
}

.ProtectionRow {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.ProtectionBar>div {
  height: 8px;
  flex-grow: 1;
  background-color: #7EDA57;
  margin: 0 4px;
  border-radius: 2px;
}

.AirClubPrice {
  color: var(--action-primary-color);
}

.OptionalServicePrivacyCheck {
  margin-right: 8px;
}

.OptionalServicesView .AirClubInfoContract{
  display: flex;
  justify-content: flex-end;
}

.OptionalServicesView .AirClubInfoContract a {
  font-size: 16px;
  color: var(--black-color);
  text-decoration: none;
}

.OptionalServicesView .DiscountPrice {
  color: #f8837e;
  font-weight: bold;
}

.OptionalServicesView .StrikeThroughPrice {
  text-decoration: line-through;
  text-decoration-thickness: 2px;
}

.OptionalServicesView .DiscountedBadge {
  z-index: 2;
  background-color: #f8837e;
  color: #fff;
  transform: rotate(50deg);
  margin-top: -15px;
  margin-right: -8px;
  width: 130px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  font-size: 16px;
  letter-spacing: -0.5px;
  font-weight: 600;
  text-transform: uppercase;
}

.OptionalServicesView .DiscountedBadgeContainer {
  position: absolute;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 75px;
  margin-top: 2px;
}

.OptionalServicesView .OptionalServiceAssistanceContent {
  padding: 0;
  text-align: center;
  position: relative;
}

.OptionalServicesView .OptionalServiceAssistanceContent header {
  background-color: #EFEFEF;
  padding: 8px;
  margin-bottom: 24px;
}

.OptionalServicesView .OptionalServiceAssistanceContent header h2,
.OptionalServicesView .OptionalServiceAssistanceContent header h3
{
  margin-bottom: 0;
  color: var(--action-brand-color);
}

.OptionalServicesView .OptionalServiceAssistanceContent header h2 {
  font-weight: bold;
}

.OptionalServicesView .OptionalServiceAssistanceContent .ProductFeatures {
  margin: auto;
  display: table;
}

.OptionalServicesView .OptionalServiceAssistanceContent .ListItemContainer {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 600;
}

.OptionalServicesView .OptionalServiceAssistanceContent .ListItemContainer .ListIcon {
  font-weight: bold;
  color: var(--action-brand-color);
  margin-right: 8px;
}

@media (min-width: 1094px) {
  .ThreeColumnView {
    display: flex;
    flex-direction: row;
  }

  .OneColumnComponent {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    align-content: center;
  }
}

@media (max-width: 1094px) {
  .ThreeColumnView {
    display: flex;
    flex-direction: column;
    align-content: center;
  }

  .OneColumnComponent {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    align-content: center;
  }
  .ThreeColumnView > :nth-child(1) { order: 2; }
  .ThreeColumnView > :nth-child(2) { order: 1; }
  .ThreeColumnView > :nth-child(3) { order: 3; }
}

.AssistanceTitle {
  font-family: Poppins-Regular, sans-serif;
  font-weight: bold;
  text-align: center;
  font-size: 24px;
  color: #223239;
}

.AssistanceSubTitle {
  font-family: Poppins-Regular, sans-serif;
  font-weight: 400;
  text-align: center;
  font-size: 18px;
  color: #223239;
}

.BaseAssistanceSubTitleContainer {
  display: flex;
  justify-content: center;
}

.SvgImageContainer {
  margin: 20px 0;
  display: flex;
  justify-content: center;
}

.AssistanceFeatureCardContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  background: #FFFFFF;
  box-shadow: 0 5px 20px -5px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  min-height: 80px;
  margin: 15px 30px;
  padding: 0 5px 0 5px;
}

.AssistanceFeatureCardContainer :nth-child(1) { flex: 1; }
.AssistanceFeatureCardContainer :nth-child(2) { flex: 3; }
.AssistanceFeatureCardContainer :nth-child(3) { flex: 1; }

.AssistanceFeatureOneColumnComponent {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 15px;
}

.AssistanceIconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--assistance-icon-container-color);
  border-radius: 12px;
  max-width: 46px;
  width: 46px;
  max-height: 46px;
  height: 46px;
}

.AssistanceIconColor {
  color: var(--assistance-icon-color);
}

.AssistanceFeatureDescriptionContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.AssistanceColumnTitle {
  font-family: Poppins-Regular, sans-serif;
  font-size: 15px;
  font-weight: 700;
  line-height: 25px;
  text-align: center;
}

.AssistanceColumnFeature {
  font-family: Poppins-Regular, sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 25px;
  text-align: center;
}

.AxaIconContainer {
  border-radius: 8px;
  overflow: hidden;
  max-width: 46px;
  width: 46px;
  max-height: 46px;
  height: 46px;
}

.BaseAssistanceProductPlanSelection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
}

.BaseAssistanceBuyButton {
  max-width: 335px;
  min-height: 55px;
  height: 55px;
  font-size: 15px;
  font-weight: 600;
  text-align: center;
}

.BaseAssistanceDontBuyButton {
  width: 104px;
  min-height: 29px;
  height: 29px;
  font-size: 15px;
  font-weight: 600;
  text-align: center;
}

.BaseAssistanceProductPlan {
  display: flex;
  align-items: center;
  margin: 10px 30px;
}

.BaseAssistancePlanPrice {
  font-family: Poppins-Regular, sans-serif;
  font-size: 15px;
  font-weight: 500;
  line-height: 25px;
}

.ColoredPriceBaseAssistance {
  color: var(--action-brand-color);
  font-weight: 700;
}

.BaseAssistanceCheckboxContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 10px;
}

.BaseAssistanceProceedButtonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.BaseAssistanceProceedButtonsMobileContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.BaseAssistanceDontBuyMobileButton {
  min-height: 55px;
  height: 55px;
  max-width: 104px;
  font-size: 15px;
  font-weight: 600;
  text-align: center;
  white-space: normal;
}

.generalConditionsCheckboxContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}
