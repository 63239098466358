.MyPageMobileContainer {
}

.MobileLeftMenu .ant-drawer-content {
  background-color: white;
  display: flex;
}

.MobileLeftMenu .ant-drawer-wrapper-body {
  flex-grow: 1;
}

.MobileLeftMenu .ant-drawer-body {
  padding: 0;
}
