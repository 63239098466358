.ChangePasswordForm {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-top: 30px;
}

.ChangePasswordForm .has-error .ant-form-explain {
  color: var(--black-color);
  opacity: 0.9;
}

@media (max-width: 840px) {
  .ChangePasswordForm  {
    flex-direction: column;
  }
  .SubmitContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-width: 100%;
  }
  .SubmitContainer .ant-btn {
    max-width: 75px;
  }
  .ant-form-explain {
    max-width: 330px;
  }
}

.SubmitContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 75px;
  margin-top: 32px;
}

@media (min-width: 840px) {
   .ant-form-explain {
    max-width: 250px;
  }
}