.ant-modal.EmbeddedDocumentModal {
    border-radius: 8px;
    background-color: #ffffff;
    position: relative;
    padding: 0;
}

.ant-modal.EmbeddedDocumentModal.AirModal .top {
    display: none;
}

.ant-modal.EmbeddedDocumentModal .title {
    height: 32px;
    color: var(--black-color);
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -0.7px;
    line-height: 32px;
}

.ant-modal.EmbeddedDocumentModal .content {
    font-size: 10px;
    padding: 10px;
}

.PrivacyUpdate .MyPageButton {
    margin: 8px;
}

.desktop.PrivacyUpdate .MyPageButton {
    width: initial;
}

.PrivacyUpdate .PrivacyTitle {
    color: var(--black-color);
    font-weight: 600;
    letter-spacing: -0.7px;
    line-height: 32px;
}

.PrivacyUpdate .Subtitle {
    opacity: 0.4;
    font-size: 16px;
    color: var(--black-color);
    font-weight: 400;
    letter-spacing: -0.6px;
    margin-bottom: 20px;
}

.PrivacyUpdate {
    color: var(--black-color);
    font-size: 14px;
    font-weight: 400;
    letter-spacing: -0.4px;
}


.PrivacyUpdate .AgreementItem {
    opacity: 0.6;
}

p {
    margin-bottom: 0px;
}

.LinkToDocument {
    opacity: 0.6;
    /*font-weight: 600;*/
    font-size: 22px;
}