.InstallerInformationView {

}

.InstallerInformationView .title {
    color: #223239;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 1px;
    margin-bottom: 25px;
}

.InstallerInformationForm {

}


.InstallerInformationForm .ant-row {
    margin: 20px 0;
}

.InstallerInformationForm .LabelInput .ant-input-disabled {
    color: var(--black-color);
}
