.VehicleView {
}

.VehicleView.desktop {
}

.VehicleView.desktop .FieldsRow {
  display: flex;
}

.VehicleView.desktop .FieldsRow > * {
  flex: 1 0 0;
}

.VehicleView.desktop .FieldsRow > *:not(:first-child) {
  margin-left: 24px;
}
