.PermanentGroup {
  margin-top: 12px;
  cursor: pointer;
}

.PermanentGroup .GroupTitle {
  height: 36px;
  color: var(--black-color);
  font-size: 16px;
  letter-spacing: -0.4px;
  margin-left: 24px;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
}

.PermanentGroup .GroupContainer {
  max-height: 0;
  overflow: hidden;
  transition: all .3s ease-out;
}

.PermanentGroup .GroupContainer.open {
  max-height: 300px;
}

.PermanentGroup .GroupTitle.active {
  font-weight: bold;
}

.PermanentGroup .ResourceIcon {
  width: 36px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
