.ProfileView {
}

.ProfileNotification .top {
  display: none;
}

.ProfileNotification.extrasmall,
.ProfileNotification.small {
  width: 90vw;
}

.ProfileNotification.desktop {
  width: 500px;
}

.ProfileNotificationArchive {
  display: inline-block;
}

.ProfileNotificationArchive .ant-table-small {
  border-radius: 10px;
  background-color: var(--light-grey-4);
  border: 1px solid var(--grey);
}

.ProfileNotificationArchive .ant-table-tbody > tr > td {
  border-bottom: 1px solid var(--grey);
}
