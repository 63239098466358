.Section {
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 40px
}

.Section .SmallDivision {
    border-radius: 0
}

.small .Section .SmallDivision,
.extrasmall .Section .SmallDivision
{
    border-radius: 8px;
}

.Section .SectionContent {
    background: white;
    padding: 16px;
}
