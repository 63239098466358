.SmallHeader {
  display: flex;
  justify-content: space-between;
}

.SmallHeader > * {
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
