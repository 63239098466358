.PermanentResource {
  height: 24px;
  margin-bottom: 12px;
  display: flex;
}

.PermanentResource .ResourcePrefix {
  width: 24px;
  display: flex;
}

.PermanentResource .ResourceIcon {
  width: 36px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.PermanentResource .ResourceTitle {
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 2px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.PermanentResource .ResourceIcon,
.PermanentResource .ResourceTitle {
  color: var(--grey-black-20-color);
  cursor: pointer;
}
.PermanentResource.Active .ResourceIcon,
.PermanentResource.Active .ResourceTitle {
  color: var(--action-primary-color);
}

.PermanentResource .ResourceSuffix {
  width: 24px;
}
