.PurchaseAssistanceView .SectionContent {
    padding: 0;
    text-align: center;
    position: relative;
}

.PurchaseAssistanceView header {
    background-color: #EFEFEF;
    padding: 8px;
    margin-bottom: 24px;
}

.PurchaseAssistanceView header h2,
.PurchaseAssistanceView header h3
{
    margin-bottom: 0;
    color: var(--action-brand-color);
}

.PurchaseAssistanceView header h2 {
    font-weight: bold;
}

.PurchaseAssistanceView .PlanPrice {
    margin-left: 8px;
    text-align: left;
    margin-bottom: 24px ;
}

.PurchaseAssistanceView .PlanPrice h2{
    color: var(--dark-grey);
    margin-bottom: 0;
    font-size: 2em;
    font-weight: bold;
}

.PurchaseAssistanceView .PlanPrice h2 .taxes{
    font-size: 14px;
    font-weight: normal;
}

.PurchaseAssistanceView .PlanPrice h3{
    color: var(--dark-grey);
    margin-bottom: 0;
    font-size: 1.2em;
    line-height: 0.7em;
    font-weight: bold;
}

.PurchaseAssistanceView .ProductFeatures {
    margin: auto;
    display: table;
}

.PurchaseAssistanceView .ListItemContainer {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    margin-bottom: 8px;
    font-size: 16px;
    font-weight: 600;
}

.PurchaseAssistanceView .ListItemContainer .ListIcon {
    font-weight: bold;
    color: var(--action-brand-color);
    margin-right: 8px;
}

.PurchaseAssistanceView .ReviewFooter {
    padding-top: 32px;
}

.desktop .PurchaseAssistanceView .ReviewFooter {
    padding: 32px 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.PurchaseAssistanceView .ReviewFooter .ReviewInfo {
    color: var(--dark-grey);
    font-weight: 600;
    font-size: 0.95em;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.desktop .PurchaseAssistanceView .ReviewFooter .ReviewInfo {
    margin-right: 16px;
}

.small .PurchaseAssistanceView .ReviewFooter .ReviewInfo,
.extrasmall .PurchaseAssistanceView .ReviewFooter .ReviewInfo {
    margin-bottom: 16px;
}

.PurchaseAssistanceView .ReviewFooter .ReviewInfo .ReviewFooterIcon {
    color: #FB8680;
    margin-right: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.desktop .PurchaseAssistanceView .ReviewFooter .MyPageButton {
    width: 200px;
}

.ProductPlanSelection {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 40px;
}

.desktop .ProductPlanSelection {
    flex-direction: row;
}

.ProductPlan {
    display: flex;
    align-items: center;
}

.PurchaseAssistanceView .DiscountPrice {
    position: absolute;
    margin-top: -20px;
    color: #f8837e;
    font-weight: bold;
    font-size: 1.4em;
}

.PurchaseAssistanceView .StrikeThroughPrice {
    text-decoration: line-through;
    text-decoration-thickness: 2px;
}

.PurchaseAssistanceView .DiscountedBadge {
    z-index: 2;
    background-color: #f8837e;
    color: #fff;
    transform: rotate(50deg);
    margin-top: 15px;
    margin-right: -35px;
    width: 130px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    font-size: 16px;
    letter-spacing: -0.5px;
    font-weight: 600;
    text-transform: uppercase;
}

.PurchaseAssistanceView .DiscountedBadgeContainer {
    position: absolute;
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

@media (min-width: 1094px) {
    .ThreeColumnView {
        display: flex;
        flex-direction: row;
    }

    .OneColumnComponent {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: flex-start;
        align-content: center;
    }
}

@media (max-width: 1094px) {
    .ThreeColumnView {
        display: flex;
        flex-direction: column;
        align-content: center;
    }

    .OneColumnComponent {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: flex-start;
        align-content: center;
    }
    .ThreeColumnView > :nth-child(1) { order: 2; }
    .ThreeColumnView > :nth-child(2) { order: 1; }
    .ThreeColumnView > :nth-child(3) { order: 3; }
}

.AssistanceTitle {
    font-family: Poppins-Regular, sans-serif;
    font-weight: bold;
    text-align: center;
    font-size: 24px;
    color: #223239;
}

.AssistanceSubTitle {
    font-family: Poppins-Regular, sans-serif;
    font-weight: 400;
    text-align: center;
    font-size: 18px;
    color: #223239;
}

.SvgImageContainer {
    margin: 20px 0;
    display: flex;
    justify-content: center;
}

.AssistanceFeatureCardContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    background: #FFFFFF;
    box-shadow: 0 5px 20px -5px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    min-height: 80px;
    margin: 15px 30px;
    padding: 0 5px 0 5px;
}

.AssistanceFeatureCardContainer :nth-child(1) { flex: 1; }
.AssistanceFeatureCardContainer :nth-child(2) { flex: 3; }
.AssistanceFeatureCardContainer :nth-child(3) { flex: 1; }

.AssistanceFeatureOneColumnComponent {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    margin-top: 15px;
}

.AssistanceIconContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--assistance-icon-container-color);
    border-radius: 12px;
    max-width: 46px;
    width: 46px;
    max-height: 46px;
    height: 46px;
}

.AssistanceIconColor {
    color: var(--assistance-icon-color);
}

.AssistanceFeatureDescriptionContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.AssistanceColumnTitle {
    font-family: Poppins-Regular, sans-serif;
    font-size: 15px;
    font-weight: 700;
    line-height: 25px;
    text-align: center;
}

.AssistanceColumnFeature {
    font-family: Poppins-Regular, sans-serif;
    font-size: 13px;
    font-weight: 400;
    line-height: 25px;
    text-align: center;
}

.AxaIconContainer {
    border-radius: 8px;
    overflow: hidden;
    max-width: 46px;
    width: 46px;
    max-height: 46px;
    height: 46px;
}

.BaseAssistanceProductPlanSelection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
}

.BaseAssistanceBuyButton {
    width: 335px;
    min-height: 55px;
    height: 55px;
}

.BaseAssistanceProductPlan {
    display: flex;
    align-items: center;
    margin: 10px 30px;
}

.BaseAssistancePlanPrice {
    font-family: Poppins-Regular, sans-serif;
    font-size: 15px;
    font-weight: 500;
    line-height: 25px;
}

.ColoredPriceBaseAssistance {
    color: var(--action-brand-color);
    font-weight: 700;
}


.BaseAssistanceCheckboxContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-left: 10px;
}
