.StateSelector {
    color: var(--black-color);
    font-size: 14px;
    letter-spacing: -0.2px;
    line-height: 20px;
}

.StateSelector .ant-select-selection {
    background-color: white;
    border-radius: 8px;
    color: var(--black-color);
    font-size: 16px;
    letter-spacing: -0.4px;
    line-height: 24px;
    padding: 0 16px;
}

.StateSelector .ant-select-selection__rendered {
   margin-left: 0;
}

.StateSelector .ant-select-disabled .ant-select-selection {
    color: var(--grey-grey-color);
}

.StateSelector.has-error .ant-select-selection {
    border-color: var(--action-danger-color);
}

.StateSelector > *:first-child:not(:last-child) {
    margin-bottom: 8px;
}

.LabelInputExplain {
    margin-top: 2px;
    color: var(--action-danger-color);
}
