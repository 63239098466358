.ReportNotificationWithActions {
}

.ReportNotificationWithActions .close {
  display: none;
}

.ReportNotificationWithActions .top {
  display: none;
}

.ReportNotificationWithActions.extrasmall,
.ReportNotificationWithActions.small {
  width: 90vw;
}

.ReportNotificationWithActions.desktop {
  width: 500px;
}

.ReportNotificationWithActions {
  display: inline-block;
}

.ReportNotificationWithActions .ant-table-small {
  border-radius: 10px;
  background-color: var(--light-grey-4);
  border: 1px solid var(--grey);
}

.ReportNotificationWithActions .ant-table-tbody > tr > td {
  border-bottom: 1px solid var(--grey);
}

.ReportNotificationWithActions .SlidePrompt {
}

.ReportNotificationWithActions .SlidePrompt > *:nth-child(1) {
  margin-bottom: 20px;
  background-color: var(--light-danger-color);
  border-radius: 4px;
  display: flex;
  align-items: center;
}

.ReportNotificationWithActions .SlidePrompt > *:nth-child(1) > *:nth-child(1) {
  color: var(--action-danger-color);
  margin-left: 4px;
}

.ReportNotificationWithActions .SlidePrompt > *:nth-child(1) > *:nth-child(2) {
  flex: 1 0 0;
  margin: 4px;
}

.ReportNotificationWithActions .SlidePrompt > *:nth-child(2) {
  margin-bottom: 20px;
}

.ReportNotificationWithActions .SlidePrompt > *:nth-child(3) {
  display: flex;
}

.ReportNotificationWithActions .SlidePrompt > *:nth-child(3) > * {
  flex: 1 0 0;
}

.ReportNotificationWithActions .SlidePrompt > *:nth-child(3) > *:not(:last-child) {
  margin-right: 4px;
}

.ReportNotificationWithActions .SlidePrompt > *:nth-child(3) > *:not(:first-child) {
  margin-left: 4px;
}

.ReportNotificationWithActions .SlidePrompt .Action {
  font-size: 22px;
  font-weight: bold;
}

.ReportNotificationWithActions .SlidePrompt .ActionSub {
  font-size: 11px;
}

.ReportNotificationWithActions .NotificationsHistory {
  display: inline-block;
}

.ReportNotificationWithActions .NotificationsHistory .ant-table-small {
  border-radius: 10px;
  background-color: var(--light-grey-4);
  border: 1px solid var(--grey);
}

.ReportNotificationWithActions .NotificationsHistory .ant-table-tbody > tr > td {
  border-bottom: 1px solid var(--grey);
}