.InstallerRow {
    display: flex;
    width: 100%;
    align-items: center;
}

.InstallerCheck {
    margin-right: 8px;
    transform: scale(1.3);
}

.InstallerFields {
    text-align: left;
    width: 100%;
    margin-bottom: 16px;
}

.InstallerRow .AvailabeOBDs {
    background-color: var(--action-primary-color);
    color: white;
    text-align: center;
}

.InstallerRow .AvailabeOBDs.noOBDs{
    background-color: var(--grey);
}

.small .InstallerRow, .extrasmall .InstallerRow {
    margin-bottom: 30px;
}
