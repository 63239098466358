.ProductCardWithSelectableRatePlanContainer {
    width: 300px;
    height: 500px;
    margin-top: 30px;
    margin-bottom: 30px;
}

.ProductCardWithSelectableRatePlanInformationContainer {
    position: relative;
    width: 250px;
    height: 380px;
    box-shadow: 0px 5px 20px -5px rgba(0, 0, 0, 0.15);
    border-width: 1px;
    border-style: solid;
    border-color: #D8D9E0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.ProductCardWithSelectableRatePlanBadgeContainer {
    position: absolute;
    top: -31px;
    left: -1px;
    align-self: start;
    background-color: #FFAC30;
    color: white;
    text-transform: uppercase;
    width: 170px;
    height: 30px;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 1px;
    text-align: center;
    border-width: 1px;
    border-bottom-width: 0;
    border-style: solid;
    border-color: #D8D9E0;
}

.ProductCardWithSelectableRateSubTitleInformationContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    text-align: center;
    height: 70px;
    font-size: 14px;
    max-width: 200px;
    color: #223239;
}

.ProductCardWithSelectableRatePlanTitleContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    text-transform: uppercase;
    color: #223239;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 1px;
    text-align: center;
    margin-top: 15px;
    max-width: 170px;
    height: 50px;
}

.ProductCardWithSelectableRatePlanImageContainer {
    position: absolute;
    top: -50px;
    left: 200px;
}

.ProductCardWithSelectableRatePlanAdditionalTitleIconContainer {
    position: absolute;
    left: -30px;
}

.highlighted {
    border-color: var(--action-brand-color);
}

.ProductCardWithSelectableRatePlanPriceOutsideContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ProductCardWithSelectableRatePlanPriceContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 135px;
    height: 55px;
    border-radius: 3px;
    color: var(--action-brand-color);
    background-color: var(--light-primary-color);
    border-width: 1px;
    border-style: solid;
    border-color: #D8D9E0;
}

.ProductCardWithSelectableRatePlanPriceContainerHighlighted {
    background-color: var(--action-brand-color);
    border-width: 0;
    color: white;
}

.ProductCardWithSelectableRatePlanPriceDiscountedContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.ProductCardWithSelectableRatePlanPriceDiscounted {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 1px;
}

.ProductCardWithSelectableRatePlanPriceOriginal {
    font-weight: 400;
    letter-spacing: 1px;
    text-decoration: line-through;
    color: #223239;
}

.ProductCardWithSelectableRatePlanPriceOriginalHighlighted {
    color: white;
}

.ProductCardWithSelectableRatePlanFeatureContainer {
    border-top: 1px solid #D8D9E0;
    padding: 10px 0 10px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 135px;
    width: 100%;
    color: #223239;
    font-size: 12px;
}

.ProductCardWithSelectableRatePlanChosePlanContainer {
    margin-top: 20px;
    padding-left: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: #223239;
    font-size: 14px;
}

.ColoredPriceProductCardWithSelectableRatePlan {
    color: var(--action-brand-color);
    font-weight: 700;
}

.ProductCardWithSelectableRatePlanCheckboxContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-left: 10px;
}

.ProductCardWithSelectableRatePlanProductPlan {
    display: flex;
    align-items: center;
    margin: 10px 30px;
}

.ProductCardWithSelectableRatePlanPlanPrice {
    font-family: Poppins-Regular, sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 25px;
}

.ProductCardWithSelectableRatePlan {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.ProductCardWithSelectableRatePlanFeature {
    font-size: 12px;
}
