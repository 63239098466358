.MyPageSelector {
  color: var(--black-color);
  border: 1px solid var(--grey-semi-color);
  background-color: white;
  border-radius: 8px;
  height: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.MyPageSelector .ant-select {
  xheight: 24px;
  color: var(--black-color);
  font-size: 17px;
  font-weight: 600;
  letter-spacing: -0.52px;
  xline-height: 24px;
}

.MyPageSelector-lg .ant-select {
  xheight: 32px;
  color: var(--black-color);
  font-size: 22px;
  font-weight: 600;
  letter-spacing: -0.7px;
  xline-height: 32px;
}

.MyPageSelector .ant-select-selection {
  background: #ffffff;
  border: none;
}

.MyPageSelectorDropdown {
  background-color: #ffffff;
}

.MyPageSelector > *:first-child {
  margin-left: 16px;
}

.MyPageSelector > *:last-child {
  margin-right: 16px;
}

.MyPageSelector-lg > *:first-child {
  margin-left: 24px;
}

.MyPageSelector-lg > *:last-child {
  margin-right: 24px;
}

.MyPageSelector-lg {
  height: 64px;
}
