.ReviewPaymentView .ListItemContainer {
    display: flex;
    align-items: center;
}

.ReviewPaymentView .ListIcon {
    color: #52AF61;
    margin-top: 2px;
    margin-right: 13px;
}

.ReviewPaymentView h3 {
    color: var(--action-brand-color);
    text-align: center;
}

.ServiceListItem {
    display: flex;
    font-size: 16px;
    color: var(--black-color);
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    line-height: normal;
}

.ServiceListItem:last-child {
    border-top: 1px solid black;
}

.ServiceListItem .ListItemSubtitle {
    color: var(--dark-grey);
    font-size: 0.9em;
}

.ServiceListItem .PriceContainer {
    display: flex;
    justify-content: space-between;
}

.ServiceListItem .DiscountedPrice {
    color: #f8837e;
}

.ServiceListItem .OriginalPriceDiscounted {
    text-decoration: line-through;
    margin-right: 15px;
}

