.MobileForgottenPassword .RecoverySteps {
  flex-grow: 1;
  margin: 16px;
}

.MobileForgottenPassword .RecoverySteps > .TwoColumns {
  display: flex;
  justify-content: space-between;
}
.MobileForgottenPassword .RecoverySteps > .TwoColumns > * {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
